import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment.dev';
import { StorageService } from './storage.service';
import { User } from '../interfaces/user';
import { rolesKeyStore, userDataKeyStore, userKeyStore } from '../constants/auth.constants';
import { DataLogin, DataLoginPortal } from '../interfaces/data-login';
import { sidebarOpenKey, userConfigKey } from '../constants/user-constants';
import { UserConfig } from '../interfaces/user-config';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  currentUser: any;
  sidebarItems: any = [];

  public userConfig: any;
  private API_URL: string = environment.API_URL;
  headers = new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('Access-Control-Allow-Origin', '*')
  .set("Access-Control-Allow-Headers", "Origin, Content-Type")
  .set("Authorization", "Bearer " + sessionStorage.getItem("cpen-token"));

  // SideBar
  mode = new BehaviorSubject(undefined);
  isOpen = new BehaviorSubject(undefined);
  isToggled = new BehaviorSubject(undefined);

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService,
  ) {

    this.userConfig = this.getUserConfig();
    this.sidebarItems = [];

    const isOpenSavedConf = this.userConfig.sidebar_isOpen;

    this.isOpen.next(isOpenSavedConf);

    const istoggledSavedConf = this.userConfig.sidebar_isToggled;
    this.isToggled.next(istoggledSavedConf);

    const modeSavedConf = this.userConfig.sidebar_mode;
    this.mode.next(modeSavedConf);
  }

  setUserData(dataLogin: DataLogin): void {

    if (dataLogin) {
      // Set User
      this.storageService.addCollection(userDataKeyStore, dataLogin.user, userKeyStore);
      // Set Roles
      this.storageService.addCollection(userDataKeyStore, dataLogin.roles, rolesKeyStore);
    }

  }

  setUserDataPortal(user,roles): void {

      // Set User
      this.storageService.addCollection(userDataKeyStore, user, userKeyStore);
      // Set Roles
      this.storageService.addCollection(userDataKeyStore, roles, rolesKeyStore);

  }

  getUserData(): any {
    return this.storageService.existData(userDataKeyStore)
    ? this.storageService.getData(userDataKeyStore)
    : null;
  }

  getUserConfig(): UserConfig {
    let config: UserConfig = JSON.parse(sessionStorage.getItem('user_config'));
    if (!config) {

      config = {
        sidebar_isToggled: false,
        sidebar_isOpen: false,
        sidebar_mode: 'side',
      }
      sessionStorage.setItem('user_config', JSON.stringify(config));
    }
    return config;
  }

  // ToggleSideNav
  updateStatusSidebar(newState: boolean): any {
    this.isToggled.next(newState);
    //this.storageService.addCollection(this.userConfig.sidebar_isOpen, newState, userConfigKey);
    this.userConfig.sidebar_isToggled = newState;
    sessionStorage.setItem('user_config', JSON.stringify(this.userConfig));
  }

  getStatusSidebar(): boolean{
    return this.storageService.getCollection(userConfigKey)
    ? this.storageService.getCollection(userConfigKey)
    : this.storageService.addCollection(sidebarOpenKey, false, userConfigKey);
  }


  // Show or not SideNav
  openClose(newState: any): any {
    this.isOpen.next(newState);
    this.userConfig.sidebar_isOpen = newState;
    sessionStorage.setItem('user_config', JSON.stringify(this.userConfig));
 }

  setUserConfig(newState: any): any{
    this.storageService.setData(userConfigKey, newState, true);
  }

  // Funciones vinculadas a consultas por Rol
  getIsRolSuperior(idConcepto:string): Observable<any>{
    const query:string = `evaluacion-desempenos/verificar-rol-superior/${idConcepto}`;

    return this.httpClient
    .get(`${this.API_URL}${query}`,
      { headers: this.headers }
    );
  }

}

import { Rol } from "./rol";
import { User } from "./user";

export interface DataLogin{
  access_token: string,
  rol_active?: string,
  user: User,
  roles: Array<Rol>
}
export interface DataLoginPortal{
  token: string
}

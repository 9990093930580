import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { environment } from 'src/environments/environment.dev';
import { AuthService } from '../services/auth.service';

export const authGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const isUserLogged = authService.isUserLogged();

  if (environment.maintenance) {
    router.navigate(['maintenance']);
    return false;
  }

  if (isUserLogged) {
    // logged in so return true
      return true;
  } else {
    // User not logged
    router.navigate(['auth/ingresar']);
    return false;
  }
}
export const canActivateChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => authGuard(route, state);

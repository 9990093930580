import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.dev';
import { StorageService } from 'src/app/core/services/storage.service';
import { UserService } from 'src/app/core/services/user.service';
import { tokenKeyStore } from '../constants/auth.constants';
import { DataLogin, DataLoginPortal } from '../interfaces/data-login';
import jwt_decode from "jwt-decode";
type ReasonLogout = 'finished' | 'expired' | undefined;



@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: any;
  private API_URL: string = environment.API_URL;
  private API_URL_PORTAL: string = environment.API_URL_PORTAL;

  reasonLogout: ReasonLogout;

  headers = new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('Access-Control-Allow-Origin', '*')
  .set("Access-Control-Allow-Headers", "Origin, Content-Type")
  .set("Access-Control-Allow-Methods", "GET, POST, PATCH, PUT, DELETE, OPTIONS");

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private matDialog: MatDialog,

    private storageService: StorageService,
    private userService: UserService,

  ) {
    this.reasonLogout = undefined;
  }

  login(dni: any, pass: any): Observable<any>{
    const query = `autenticar/login`;
    const url = this.API_URL + query;

    const formData = {
      username: dni,
      password: pass
    };


    return this.httpClient.post<any>(url, formData, {'headers':this.headers});
  }

  /* NuevoPortal */

  loginExterno(token: string): Observable<any>{
    const query = `auth/validar-token-externo`;
    const url = this.API_URL_PORTAL + query;

    const formData = {
      token: token,
    };

    return this.httpClient.post<any>(url, formData, {'headers':this.headers});
  }

  /* Guarda el token al localstorage */
  setDataLogin(dataLogin : DataLogin): void {
   //TODO
  }

  setDataLoginPortal(token : string): void {
    this.setToken(token);
  }

  // Función para decodificar el token obtenido
  decodeJwtToken(): any {
    const token = this.getToken();
    if (token) {
      try {
        return jwt_decode(token);
      } catch (error) {
        //console.error('Error decoding JWT:', error);
        return null;
      }
    }
    return null;
  }

  getRolUuid() : any {
    const token = this.decodeJwtToken();
    return token.ucpe.rol ? token.ucpe.rol.id : null;
  }

  getRolNombre() : any {
    const token = this.decodeJwtToken();
    return token.ucpe.rol ? token.ucpe.rol.nombre : null;
  }

  getRolDescripcion() : any {
    const token = this.decodeJwtToken();
    return token.ucpe.rol ? token.ucpe.rol.descripcion : null;
  }


  // Función para obtener el usuario del token decodificado
  getUsuario() : any {
    const token = this.decodeJwtToken();
    return token.ucpe.usuario ? token.ucpe.usuario : null;
  }

  getUsuarioUuid() : string {
    const token = this.decodeJwtToken();
    return token.ucpe.usuario ? token.ucpe.usuario.id : null;
  }
  getUsuarioNombreCompleto() : string {
    const token = this.decodeJwtToken();

    return token.ucpe.usuario.apellido + ', ' + token.ucpe.usuario.nombre;
  }

  getUsuarioNombre() : string {
    const token = this.decodeJwtToken();
    return token.ucpe.usuario.nombre;
  }

  getUsuarioApellido() : string {
    const token = this.decodeJwtToken();
    return token.ucpe.ucpe.apellido;
  }



  isUserLogged(): boolean {
    const accessToken: any = sessionStorage.getItem(tokenKeyStore);
    return accessToken != null;
  }

  setToken(token: string): void{
    if(this.storageService.existData(tokenKeyStore) === false){
      this.storageService.setData(tokenKeyStore, token, false);
    }else{
      this.storageService.removeData(tokenKeyStore);
      this.storageService.setData(tokenKeyStore, token, false);
    }
  }

  getToken(): string{
    return sessionStorage.getItem(tokenKeyStore);
    //TODO ver con kevin anda raro
    if (this.storageService.existData(tokenKeyStore)) {
      return this.storageService.getData(tokenKeyStore);
    }
    return '';
  }

  cambiarRol(rol:any, token:string){
    const query = `autenticar/cambiar-rol`;
    const url = this.API_URL + query;

    this.updateRol(token);

    const formData = {
      idRol: rol.rol_id,
    };


    return this.httpClient.post<any>(url, formData, {'headers':this.headers});
  }

  findRolActivo(roles: Array<any>){
    return roles.find(rol => rol.rol_activo === 1);
  }

  findRolActivoPortal(roles: Array<any>){
    return roles.find(rol => rol.ra === 1);
  }

  updateRol(token:string){
    this.storageService.removeData('cpen-token');
    this.storageService.setData('cpen-token', token, false);
  }

  setLogOutReason(reason:ReasonLogout): void{
    this.reasonLogout = reason;
  }
  getLogOutReason(): ReasonLogout{
    return this.reasonLogout;
  }

  closeAllDialogs(): void{
    this.matDialog.closeAll();
  }

  restablecerClave(body:any):Observable<any>{
    const query:string = 'auth/restablecer-clave';
    return this.httpClient
    .post(`${this.API_URL}${query}`, body,
      {headers: this.headers}
    );
  }

  validarTokenRestablecerClave($token:any): Observable<any>{
    const query:string = 'auth/validar-token';
    const params:string = `?token=`+$token;

    return this.httpClient
    .get(`${this.API_URL}${query}${params}`,
      {headers: this.headers}
    );
  }

  cambiarClave(body:any): Observable<any>{
    const query:string = 'auth/cambiar-clave';

    /*
      TODO: body debe tener las 2 claves encriptadas
    */
    return this.httpClient
      .put(`${this.API_URL}${query}`, body,
        {headers: this.headers}
      );
  }


  logOut(reason:ReasonLogout): void {
    const query = `autenticar/logout`;
    const url = this.API_URL + query;

    this.setLogOutReason(reason);
    this.closeAllDialogs();
    this.storageService.clearLocalstorage();

    this.httpClient.post<any>(url, {});
    this.router.navigateByUrl('auth/ingresar');
  }

}

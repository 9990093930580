import { Injectable } from '@angular/core';
import { userConfigKey } from '../constants/user-constants';

@Injectable({ providedIn: 'root' })
export class StorageService {

  defaultValue: string = '';

  constructor() {}

  /**
   * Setea un dato en el localStorage 22/01/24 Cambio a sessionStorage hasta utilizar cookies
   * @param key La clave con prefijo cpen-
   * @param data El valor a guardar
   * @param json ¿Es JSON?
   */
  setData(key: string, data: any, json: boolean) {
    let jsonData;
    if (json) {
      jsonData = JSON.stringify(data);
      sessionStorage.setItem(key, jsonData);
    } else {
      sessionStorage.setItem(key, data);
    }
  }

  getData(key: string) {
    let data:any;

    try{
        data = JSON.parse(sessionStorage.getItem('filters')!) || this.defaultValue;
    }catch(e){
        data = sessionStorage.getItem(key);
    }
    return data;
  }

  removeData(key: string) {
    sessionStorage.removeItem(key);
  }

  existData(key: string): boolean{
    return !!sessionStorage.getItem(key);
  }

  addFilter(key: string, data: any){
    let jsonData;
    let filters = JSON.parse(sessionStorage.getItem('filters')!) || this.defaultValue;

    if(filters !== ''){
      filters[key] = data;
      this.setData('filters',filters,true);

    }else{

      jsonData = {[key]:data};
      this.removeData('filters');
      this.setData('filters',jsonData,true);
    }
  }
  getFilter(key: string){
    let filters = JSON.parse(sessionStorage.getItem('filters')!) || this.defaultValue;
    if(filters){
      return filters[key]
    }
  }
  removeFilter(key: string){
    let filters = JSON.parse(sessionStorage.getItem('filters')!) || this.defaultValue;
    if(filters !== ''){
      delete filters[key];
      this.setData('filters',filters,true)
    }
  }

  addCollection(collectionKey: string, data: any, key: string){
    let jsonData;
    let collection = JSON.parse(sessionStorage.getItem(collectionKey)!) || this.defaultValue;

    if(collection !== ''){
      collection[key] = data;
      this.setData(collectionKey, collection, true);

    }else{

      jsonData = {[key]:data};
      this.removeData(collectionKey);
      this.setData(collectionKey, jsonData, true);
    }
  }
  getCollection(collectionKey: string){
    let collection = JSON.parse(sessionStorage.getItem(collectionKey)!) || this.defaultValue;
    if(collection){
      return collection;
    }
  }
  removeDataFromCollection(key: string, collectionKey: string){
    let collection = JSON.parse(sessionStorage.getItem(collectionKey)!) || this.defaultValue;
    if(collection !== ''){
      delete collection[key];
      this.setData(collectionKey, collection, true);
    }
  }

  clearLocalstorage(): void{
    const userConfig:string = this.getCollection(userConfigKey);
    sessionStorage.clear();
    this.setData(userConfigKey, userConfig, true);

  }
}

/**
 * Constants for Authentication
 */

import { DataLogin } from "../interfaces/data-login";

export const tokenKeyStore: string = 'token';
export const userDataKeyStore: string = 'user_data';
export const userKeyStore: string = 'user'
export const rolesKeyStore: string = 'roles';

// No usado en environment para poder compilar la app.
// Invocado en componente de login
export const OFFLINE_MODE_AUTH: boolean = true;


export const dataLoginSimulationMode:DataLogin = {
  access_token: '123123',
  user: {
    uuid: '123123',
    username: 'Testing App',
    name: 'Testing',
    last_name: 'App',
  },
  roles: [
    {
      rol_id: '1',
      nombre: 'User'
    }
  ]
}
